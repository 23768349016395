.wrap-ach {
  background: rgba(53, 50, 59, 0.9);
  position: absolute;
  width: 100vw;
  height: 100vh;
  //max-height: 760px;
  z-index: 7;
  display: flex;
  justify-content: center;
  align-items: center;
}
.modal {
  position: absolute;
  filter: blur(0);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: column;
  background: #ffffff;
  border-radius: 32px;
  width: 90%;
  max-width: 320px;
  height: 359px;
  box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);

  .ava {
    width: 200px;
    min-height: 200px;
    background: #333333;
    border: 6px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 0px 32px rgba(0, 0, 0, 0.25);
    border-radius: 24px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-20px);
    img {
      width: 200px;
      height: 200px;
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding-bottom: 36px;
    h3 {
      //padding-top: 16px;
      font-family: Geometria;
      width: 100%;
      font-style: normal;
      font-weight: 800;
      font-size: 24px;
      line-height: 120%;
      color: #35323b;
      margin: 8px 0 8px 0;
    }
    p {
      font-family: Geometria;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 140%;
      text-align: center;
      color: #35323b;
      margin-bottom: 16px;
    }

    button {
      margin-top: 8px;
      width: 190px;
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 44px;
      background: #ffb016;
      box-shadow: 0px 4px 16px rgba(255, 176, 22, 0.5);
      border-radius: 12px;
      color: white;
      padding: 0 30px;
      font-family: Geometria;
      font-style: normal;
      font-weight: bold;
      font-size: 16px;
      line-height: 120%;
      border: 0;
    }
  }
}
