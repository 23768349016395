a {
    &:link,
    &:visited,
    &:hover,
    &:active {
        color: map_get($colors, "black");
    }

    font-family: 'Geometria-Medium';
}
