.form {
  $backplate-w: 87.5%;
  $input-margin: 0.5rem;

  .content {
    div.back-btn {
      position: absolute;
      left: 18px;
      top: 8%;
      cursor: pointer;
      span {
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }
    .wrapper {
      h2 {
        margin: 0.2rem 0;
        line-height: 14.4px;
        font-size: 12px;
        font-family: 'Geometria-Bold';
      }

      p {
        margin: 0.2rem 0;
        line-height: 16.8px;
        font-size: 12px;
      }

      &.pass {
        span {
          p {
            color: map_get($colors, 'red');
            display: block !important;
            text-align: center;
          }

          a {
            &:link,
            &:visited,
            &:hover,
            &:active {
              color: map_get($colors, 'yellow');
            }
          }

          :first-child {
            margin-top: 0;
          }

          :last-child {
            margin-bottom: 0;
          }

          font-size: 12px;
          line-height: 16.8px;
          font-family: 'Geometria-Medium';
          position: absolute;
          top: calc(100% + 16px - #{$input-margin});
          width: 100%;
        }
      }

      form {
        position: relative;
      }

      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: $backplate-w;
      margin: auto;
    }

    .footer {
      $margin-lr: 32px;
      span {
        ::after {
          content: '•';
          color: map_get($colors, 'black');
        }
        .pass::after {
          color: map_get($colors, 'yellow');
        }

        color: map_get($colors, 'yellow');
        position: relative;
        font-size: xx-large;
        width: 100%;
        text-align: left;
      }

      a {
        font-size: 12px;
        line-height: 16.8px;
        white-space: nowrap;
      }

      display: flex;
      justify-content: center;
      position: absolute;
      align-items: center;
      bottom: 0;
      left: 0;
      width: calc(100% - #{$margin-lr} * 2);
      margin: 24px $margin-lr;
      line-height: 16.8px;
    }

    & > .icon {
      :last-child {
        position: absolute;
        left: 0;
        top: 0.25rem;
        color: #f2994a;
      }

      position: absolute;
      font-size: 4rem;
      top: 1.4rem;
    }

    .icon-logo {
      transition: 0.3s;
      position: absolute;
      top: 8%;
      max-height: 20%;
    }

    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 5;
    height: 100%;
    width: 100%;
    border-radius: 32px;
    box-shadow: 0px 5px 35px 0px rgba(0, 0, 0, 0.5);
    background-color: #fff;
  }

  .backplate {
    position: absolute;
    bottom: -0.8rem;
    width: $backplate-w;
    height: $backplate-w;
    background-color: #fff;
    border-radius: 24px;
  }

  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  text-align: center;
  max-width: 360px;
  width: calc(100% - 16px);
  height: 340px;
  margin: 8px;
  color: map_get($colors, 'black');
}
