@import '_sass/colors';
@import '_sass/input';
@import '_sass/checkbox';
@import '_sass/link';
@import '_sass/_fonts/style';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,700&display=swap');

html,
body,
#root {
  width: 100%;
  height: 100%;
  background-color: rgba(18, 12, 38, 1);
  //background-color: red;
}

body {
  margin: 0;
  font-family: Geometria, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

img {
  display: flex;
  align-items: center;
  justify-content: center;
}

p {
  display: flex;
  align-items: center;
}
