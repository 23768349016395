@media (max-width: 321px) {
  .edit-info {
    /** MODAL DIALOG */
    .wrap-modal {
      .modal-bg {
        //height: 142vh;
        .modal-window {
          height: 240px;
          width: calc(100% - 16px);
          max-width: 300px;
          .modal-content {
            width: 90%;
            h3 {
              font-size: 20px;
              margin: 16px 0;
            }
            .buttons {
              margin: 16px 0;
            }
          }
        }
      }
    }
  }
}
