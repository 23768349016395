input[type='checkbox'] {
    &:invalid {
        border-color: #eb5757;
        box-shadow: none;
    }
    &:checked {
        border-color: map_get($colors, "yellow");
    }

    height: 18px;
    width: 18px;
    margin: 0 8px 0 0;
    padding: 0;
    border: solid 0.8px map_get($colors, "black");
    border-radius: 9px;
    outline: none;
    text-align: center;
}

.checkmark:after {
    display: none;
    position: absolute;
    content: "";
    left: 6px;
    top: 3px;
    width: 5px;
    height: 8px;
    border: solid map_get($colors, "yellow");
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
}

input:checked ~ .checkmark:after {
    display: block;
}
