$colors : (
    "yellow": #f2994a,
    "black": #35323b,
    "red": #eb5757,
    "grey": #828282,
    "grey-span": #4f4f4f,
    "img-background": #e0e0e0,
    "blue-grey": #35323b,
    "h2-main-page-white": #f2f2f2,
    "progress-bar-background": #4f4f4f,
);
