input:not([type=radio]) {
    $input-border-w: 2px;
    $input-margin: 0.5rem;

    &:invalid {
        border-color: map_get($colors, "red");
        box-shadow: none;
    }

    &:disabled {
        border-color: map_get($colors, "grey");
        color: map_get($colors, "grey");
    }

    height: 40px - ($input-border-w * 2);
    background-color: transparent;
    border: solid $input-border-w map_get($colors, "black");
    border-radius: 8px;
    margin: $input-margin 0;
    font-size: 16px;
    font-family: 'Geometria-Medium';
    line-height: 22.4px;
    color: map_get($colors, "yellow");
    caret-color: map_get($colors, "yellow");
    appearance: none;
}
